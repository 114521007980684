<template>
  <el-card class="job-card">
    <el-form slot="header" class="searchFrom square-input-warp square-btn-warp" size="small"
             :inline="true"
             lable-width="80px" :model="searchForm" ref="searchForms">
      <el-form-item label="兼职编号:" prop="jobid">
        <el-input v-model="searchForm.jobid" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="兼职岗位:" prop="president">
        <el-input v-model="searchForm.president" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="工作模式:" prop="jobmode">
        <el-select v-model="searchForm.jobmode" placeholder="全部">
          <template v-for="jobmode in jobmodeStatus">
            <el-option :key="jobmode.name" :label="jobmode.name" :value="jobmode.value"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="上架模式:" prop="status">
        <el-select v-model="searchForm.status" placeholder="全部">
          <template v-for="status in statusStatus">
            <el-option :label="status.name" :value="status.value"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态:" prop="auditflag">
        <el-select v-model="searchForm.auditflag" placeholder="全部">
          <template v-for="status in auditStatus">
            <el-option :label="status.name" :value="status.value"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-button size="small" type="primary" class="sctp-square" @click="queryjob">搜索</el-button>
      <el-button size="small" class="sctp-square" @click="reset()">重置</el-button>
      <el-button @click="goPage('/user/jobedit')" class="sctp-square" type="primary" size="small"
                 icon="el-icon-plus">
        发布兼职
      </el-button>
    </el-form>
    <el-row class="sctp-bg-f8 sctp-pad-tb10">
      <el-col :span="2">
        <div class="sctp-tc">图片</div>
      </el-col>
      <el-col :span="6">
        <div class="sctp-tc">兼职岗位</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">期望薪水</div>
      </el-col>
      <el-col :span="3">
        <div class="sctp-tc">可工作时间</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">浏览数</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">查看量</div>
      </el-col>
      <el-col :span="3">
        <div class="sctp-tc">工作模式</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">审核状态</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">操作</div>
      </el-col>
    </el-row>
    <template v-if="jobData && jobData.length > 0">
      <template v-for="job in jobData">
      <div :key="job.jobid" class="job-item">
        <div class="sctp-flex sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-f8 sctp-info"
             style="position: relative">
          <div style="margin-right: 30px;">兼职编号：{{ job.jobid }}</div>
          <div style="margin-right: 30px;">提交时间：{{ job.createtime }}</div>
          <div style="margin-right: 30px;">上次修改时间：{{ job.updatetime }}</div>
          <div style="margin-right: 30px;">上架截至时间：{{ job.deadline }}</div>
          <div style="position: absolute;right: 20px;">
            <template v-if="job.views">
              <el-tooltip effect="dark" content="已被查看无法被删除" placement="top">
                <a><i class="el-icon-delete fz-14"></i></a>
              </el-tooltip>
            </template>
            <template v-else>
              <a title="删除该兼职" @click="deleteRequire(job.jobid)"><i
                class="el-icon-delete fz-14"></i></a>
            </template>
          </div>
        </div>
        <el-row type="flex" align="middle" class="sctp-pad-tb15">
          <el-col :span="2">
            <el-image
              style="width: 65px; height: 65px;padding-left: 5px"
              :src="job.imgurl"
              fit="contain">
              <div slot="error" class="sctp-flex sctp-flex-center sctp-bg-gray"
                   style="height: 100%;">
                暂无图片
              </div>
            </el-image>
          </el-col>
          <el-col :span="6">
            <div>
              <router-link :to="'/parttime/view/' + job.jobid">
                <h4 class="sctp-ellipsis--l2 sctp-tc">
                  {{ job.president }}
                </h4>
                <h6 class="sctp-ellipsis--l2 sctp-tc12">
                  {{ job.typename }}
                </h6>
                <h6 class="sctp-ellipsis--l2 sctp-tc12">
                  {{ job.skillname }}
                </h6>
              </router-link>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">
              <h4 class="sctp-ellipsis--l2">{{ job.expectedSalary }}/{{ job.salaryUnit }}</h4>
              <i
                @click="updatePriceConfig.onShowClick(job)"
                class="el-icon-edit"></i>
            </div>
          </el-col>
          <el-col :span="3">
            <template v-if="job.jobtime && job.jobtime === 1">
              <h4 class="sctp-ellipsis--l2 sctp-tc">全部</h4>
            </template>
            <template v-if="job.jobtime && job.jobtime === 2">
              <h4 class="sctp-ellipsis--l2 sctp-tc">工作日</h4>
            </template>
            <template v-if="job.jobtime && job.jobtime === 3">
              <h4 class="sctp-ellipsis--l2 sctp-tc">周末</h4>
            </template>
            <template v-if="job.jobtime && job.jobtime === 4">
              <h4 class="sctp-ellipsis--l2 sctp-tc">节假日</h4>
            </template>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">
              <h4 class="sctp-ellipsis--l2">{{ job.hits }}</h4>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">
              <el-tooltip effect="dark" content="查看访问用户" placement="top">
                <el-tag
                  size="small"
                  @click="accessUserHandler(job.jobid)"
                  style="cursor: pointer;"
                >
                  {{ job.views || 0 }}
                </el-tag>
              </el-tooltip>
            </div>
          </el-col>
          <el-col :span="3">
            <template v-if="job.jobmode && job.jobmode === 1">
              <h4 class="sctp-ellipsis--l2 sctp-tc">远程在家</h4>
            </template>
            <template v-if="job.jobmode && job.jobmode === 2">
              <h4 class="sctp-ellipsis--l2 sctp-tc">偶尔到岗</h4>
            </template>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">
              <template v-if="job.auditflag || job.auditflag === 0">
                <el-tooltip
                  effect="dark"
                  :disabled="job.auditflag == 0"
                  :content="job.auditDesc" placement="top"
                >
                  <el-tag
                    style="cursor: pointer;"
                    size="mini" :type="auditStatusTag[job.auditflag].type"
                  >
                    {{ auditStatusTag[job.auditflag].name }}
                  </el-tag>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="点击查看审核历史" placement="top">
                  <a
                    @click="auditHistoryHandler(job.jobid)"
                  ><i class="el-icon-question"></i></a>
                </el-tooltip>
              </template>
            </div>
          </el-col>
          <el-col :span="2">
            <template v-if="job.auditflag === 1 || job.auditflag === 3">
              <template v-if="job.status === 1">
                <div class="sctp-mar-b5 sctp-tc">
                  <el-button
                    size="mini"
                    @click="onOffShelvesClick(job.jobid)"
                  >
                    下架
                  </el-button>
                </div>
              </template>
              <template v-else>
                <div class="sctp-mar-b5 sctp-tc">
                  <el-button
                    size="mini"
                    @click="onUpShelvesClick(job)"
                  >
                    上架
                  </el-button>
                </div>
              </template>
            </template>
            <template>
              <div class="sctp-mar-b5 sctp-tc">
                <el-button size="mini"
                           @click="handleEdit(job)">
                  编辑
                </el-button>
              </div>
            </template>
            <template>
              <div class="sctp-mar-b5 sctp-tc">
                <el-button size="mini" type="primary"
                           @click="handlePreview(job.jobid)">
                  预览
                </el-button>
              </div>
            </template>
          </el-col>
        </el-row>
      </div>
    </template>
    </template>
    <template v-else>
      <div class="empty-text">暂无数据</div>
    </template>
    <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
      <el-pagination
        :current-page="pageConfig.page"
        :page-size="pageConfig.limit"
        @size-change="pageConfig.handleSizeChange"
        @current-change="pageConfig.handleCurrentChange"
        :page-sizes="pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="pageConfig.total">
      </el-pagination>
    </div>
    <el-dialog title="访问用户" :visible.sync="showAccessUser">
      <access-user type="PARTTIME" :id="jobid"></access-user>
    </el-dialog>
    <el-dialog title="审核历史" class="auth_history" :visible.sync="showAuditHistory">
      <audit-history type="PARTTIME" :id="jobid"></audit-history>
    </el-dialog>
    <el-dialog width="600px" center title="本兼职发布需支付服务费" :visible.sync="payDialogConfig.showFlag">
      <pay-dialog type="PARTTIME" @pay="payDialogConfig.onPay"></pay-dialog>
    </el-dialog>
    <el-dialog width="500px" center title="编辑价格" :visible.sync="updatePriceConfig.show">
      <el-form :model="updatePriceConfig.data" :rules="updatePriceConfig.rules"
               :ref="updatePriceConfig.formName">

        <el-form-item class="inline-form" label="期望薪水" prop="expectedSalary">
          <el-input type="number" v-model="updatePriceConfig.data.expectedSalary"></el-input>
          <span>&emsp;&emsp;单位:</span>
          <el-select v-model="updatePriceConfig.data.salaryUnit" placeholder="">
            <el-option
              v-for="item in updatePriceConfig.unitOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary"
                     @click="updatePriceConfig.onSubmitClick">提交修改
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import {goods} from '@/apis/index'

const config = require('../../../resources/js/config');
export default {
  name: 'job',
  components: {
    AccessUser: () => import('@PAGE/users-center-manage/parttime-manage/AccessUserHistory.vue'),
    AuditHistory: () => import('@PAGE/users-center-manage/AuditHistory.vue'),
    payDialog: () => import('@PAGE/users-center-manage/requirement-manage/RequirementOnlinePay')
  },
  data() {
    return {
      searchForm: {
        jobid: null,
        president: null,
        jobmode: null,
        status: null,
        auditflag: null
      },
      auditStatusTag: [
        {name: '待审核', type: 'info'},
        {name: '已通过', type: 'success'},
        {name: '未通过', type: 'danger'},
        {name: '强制下架', type: 'danger'}
      ],
      jobData: [],
      jobid: null,
      updatePriceConfig: {
        formName: 'updatePriceDialogFormName',
        show: false,
        unitOptions: [
          {label: '按小时', value: '小时'},
          {label: '按天', value: '天'},
          {label: '按月', value: '月'},
          {label: '按工作量', value: '按工作量'},
          {label: '按项目', value: '按项目'},
          {label: '按次', value: '次'},
          {label: '商议', value: '商议'},
        ],
        model: {},
        data: {
          expectedSalary: null,
          salaryUnit: '月',
        },
        rules: {
          expectedSalary: [
            {required: true, message: '请输入期望薪水', trigger: 'change'},{
              validator: (rule, value, callback) => {
                if (value < 0){
                  callback(new Error('期望薪水不能为负数'))
                } else {
                  callback()
                }
              }
            }
          ],
          salaryUnit: [
            {required: true, message: '请选择期望薪水单位', trigger: 'change'},
          ],
        },
        onShowClick: (item) => {
          this.jobid = item.jobid;
          this.updatePriceConfig.show = true;
        },
        onSubmitClick: () => {
          this.$refs[this.updatePriceConfig.formName].validate()
          .then(() => {
            let {expectedSalary, salaryUnit} = this.updatePriceConfig.data;
            goods.updateJobPrice({
              id: this.jobid,
              expectedSalary, salaryUnit,
            }).then(() => {
              this.updatePriceConfig.show = false;
              this.$message.success('保存成功！');
              this.queryjob();
            })
          });
        },
      },
      showAccessUser: false,
      showAuditHistory: false,
      pageConfig: {
        page: 1,
        limit: 10,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        handleSizeChange: (limit) => {
          this.pageConfig.limit = limit;
          this.queryjob()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.queryjob()
        }
      },
      payDialogConfig: {
        showFlag: false,
        showUpperShelvesDialog: () => {
          this.payDialogConfig.showFlag = true
        },
        onPublishPaySuccess: (id) => {
          this.payDialogConfig.showFlag = false;
          this.doChangeStatus(1, id)
        },
        onPay: ({payWay, price, date}) => {
          return this.$request.post({
            reqcode: '1155',
            reqdesc: '兼职上架',
            payWay: payWay,
            date: date,
            jobid: this.jobid,
            userId: this.user.userId
          }).then(res => {
            this.payDialogConfig.showFlag = false;
            this.$message.success('操作成功');
            this.queryjob()
          })
        }
      }
    }
  },
  methods: {
    handlePreview(jobid) {
      this.openPage(`/parttime/view/${jobid}`)
    },
    queryjob() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      if (searchForm.createTimeLimit) {
        searchForm.createStart = this.dateToString(new Date(searchForm.createTimeLimit[0]),
          'yyyy-MM-dd 00:00:00');
        searchForm.createEnd = this.dateToString(new Date(searchForm.createTimeLimit[0]),
          'yyyy-MM-dd 23:59:59');
        delete searchForm.createTimeLimit
      }
      const {pageConfig: {page, limit}} = this;
      this.$request.post({
        reqcode: '1144',
        reqdesc: '我的兼职',
        ...searchForm,
        userId: this.user.userId,
        page: page,
        limit: limit
      }).then(res => {

        const {data = [], count = 0, types = []} = res;
        data.forEach(item => {
          if (item.createtime) {
            item.createtime = this.dateToString(new Date(item.createtime), 'yyyy-MM-dd HH:mm:ss')
          }
          if (item.updatetime) {
            item.updatetime = this.dateToString(new Date(item.updatetime), 'yyyy-MM-dd HH:mm:ss')
          }
          if (item.expectdate) {
            item.expectdate = this.dateToString(new Date(item.expectdate), 'yyyy-MM-dd HH:mm:ss')
          }
          if (item.shelvesDeadline) {
            item.deadline = this.dateToString(new Date(item.shelvesDeadline),
              'yyyy-MM-dd HH:mm:ss');
            if (item.shelvesDeadline < new Date().getTime()) {
              item.deadline = item.deadline + '(已过期)'
            }
          } else {
            item.deadline = '未上架'
          }
          item.imgurl =  item.imgurl
          const type = types.filter(type => type.reqtypeid === item.reqtypeid)[0];
          if (type) {
            item.typeStr = type.typename
          }
          item.selected = false
        });
        this.jobData = data;
        this.pageConfig.total = count
      })
    },
    onChangeStatus(status, job) {
      this.jobid = job.jobid;
      if (status === 1) {
        job.status = 0;
        this.payDialogConfig.showFlag = true
      } else {
        this.doChangeStatus(status, job.jobid)
      }
    },
    /**
     * 下架
     */
    onOffShelvesClick(jobid) {
      this.jobid = jobid;
      this.$confirm('还未到截至时间,确定要下架吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.doChangeStatus(0, jobid).then(() => {
          this.$message.success('下架成功');
        });
      })
    },
    /**
     * 上架,
     * 检查是否到期，如果到期，打开申请支付，否则上架
     */
    onUpShelvesClick(job) {
      this.jobid = job.jobid;
      if (job.auditflag === 3) {
        this.$confirm(`您的兼职由于‘${job.auditmemo}’被强制下架,请重新编辑`, '提示', {
          type: 'warning'
        }).then(() => {
          this.$router.push({
            path: '/user/jobedit',
            query: {
              jobid: job.jobid
            }
          })
        });
        return
      }
      this.doChangeStatus(1, job.jobid).then(() => {
        this.$message.success('上架成功');
      });
    },
    deleteRequire(jobid) {
      this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$request.post({
          reqcode: '1145',
          reqdesc: '删除兼职',
          jobid: jobid,
          userId: this.user.userId
        }).then(res => {
          this.$message.success('删除成功');
          this.queryjob()
        })
      })
    },
    accessUserHandler(jobid) {
      this.jobid = jobid;
      this.showAccessUser = true
    },
    auditHistoryHandler(jobid) {
      this.jobid = jobid;
      this.showAuditHistory = true
    },
    setAllStatus(flag) {
      if (this.jobData) {
        this.jobData.forEach(job => {
          job.selected = !!flag
        })
      }
    },
    handleEdit(job) {
      let {jobid, status, auditflag} = job;
      let editPage = () => {
        this.$router.push({
          path: '/user/jobedit',
          query: {
            jobid: jobid
          }
        })
      };
      if (auditflag === 1) {
        this.$confirm(`${status === 1 ? '编辑需要先下架，' : ''}重新编辑后需要重新审核，确定编辑吗？`, '提示', {
          type: 'warning'
        }).then(() => {
          if (status === 1) {
            this.doChangeStatus(0, jobid).then(res => {
              editPage();
            });
          } else {
            editPage();
          }
        });
        return;
      }
      editPage();
    },
    doChangeStatus(status, jobid) {
      return this.$request.post({
        reqcode: '1156',
        reqdesc: '修改需求状态',
        userId: this.user.userId,
        jobid: jobid,
        status: status
      }).then(res => {
        this.queryjob()
      }).catch(res => {
        if (res && res.retCode === '9002') {
          this.payDialogConfig.showFlag = true;
        }
        return Promise.reject(res);
      })
    },
    reset(){
      this.$refs.searchForms.resetFields()
    }
  },
  computed: {
    selectAll() {
      let flag = false;
      if (this.jobData && this.jobData.length > 0) {
        flag = this.jobData.every(job => {
          return job.selected
        })
      }
      return flag
    },
    indeterminate() {
      if (this.selectAll) {
        return false
      } else {
        let flag = false;
        if (this.jobData) {
          flag = this.jobData.some(job => {
            return job.selected
          })
        }
        return flag
      }
    },
    auditStatus() {
      return [
        {name: '全部', value: null},
        {name: '待审核', value: 0},
        {name: '已通过', value: 1},
        {name: '未通过', value: 2},
        {name: '强制下架', value: 3}
      ]
    },
    statusStatus() {
      return [
        {name: '全部', value: null},
        {name: '未上架', value: 0},
        {name: '已上架', value: 1}

      ]
    },
    jobmodeStatus() {
      return [
        {name: '全部', value: null},
        {name: '远程在家', value: 1},
        {name: '偶尔到岗', value: 2}
      ]
    }
  },
  beforeMount() {
    this.queryjob()
  }
}
</script>

<style scoped lang="scss">
.job-card {
  /deep/ {
    .el-input {
      width: 110px !important;
    }

    .el-card__body {
      padding: 12px;
    }

    .job-item {
      .el-icon-edit {
        display: none;
        cursor: pointer;
      }

      :hover .el-icon-edit {
        display: inline-block;
      }
    }
  }
}

.auth_history /deep/ {
  & .el-dialog__body {
    padding-top: 0;
  }

  & .el-dialog {
    width: 80%;
  }

  & .el-dialog__body {
    max-height: 50vh;
    overflow-y: auto;
  }
}

.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 280px;
}

.job-item {
  border: 1px solid #e5e5e5;
  margin-top: 15px;
  vertical-align: middle;
}

.sctp-tc12 {
  font-size: 12px;
  text-align: center;
  padding-top: 10px;
}
</style>
